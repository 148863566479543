import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/ConvertItems/Add';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/ConvertItems/ConvertItemStore';
import { GetItems, SetAxiosPrivate as SetAxiosPrivateItem, GetWhitoutConvert } from '../../../Functions/Catalogs/Items/ItemStore';
import { Add as AddItem } from '../../Shared/ItemList/Add';
import { Pivot, PivotItem } from '@fluentui/react';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateItem(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const initial = {
        convertedItem: []

    };
    const [itemSave, setItemSave] = useState(initial);
    const [showSpinner, setShowSpinner] = useState(false);
    const [disabledComboBox, setDisabledComboBox] = useState(false);
    const [itemList, setItemList] = useState();
    const [itemLoadWhitoutConvert, setItemLoadWhitoutConvert] = useState();
    const [itemLoad, setItemLoad] = useState();
    const [itemListConverted, setItemListConverted] = useState();
    const [serch, setSerch] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (itemSave.convertedItem.length<=0) {
            errorMessages.push("Debe agregar artículos a la lista.");
        }
        if (errorMessages.length > 0) {

            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            var data = itemSave;
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                data.name = itemSave.convertItem;
                addNewItem({ ...data, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    const OnLoadGetWhitoutConvert = async () => {
        let result = await GetWhitoutConvert();
        if (result.isSuccessful) {
            setItemLoadWhitoutConvert(result);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadGetItems = async () => {
        let result = await GetItems();
        if (result.isSuccessful) {
            setItemLoad(result);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnLoadGetWhitoutConvert();
        OnLoadGetItems();
    }, [])
    function LoadList(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = itemLoadWhitoutConvert.data.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.description.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.description
                })).slice(0, 100);;
                setItemList(resultList);
            }
        }
    }
    useEffect(() => {
        if (itemLoadWhitoutConvert) {
            LoadList(serch)
        }
    }, [itemLoadWhitoutConvert]);
    const OnChangeText = async (text) => {
        if (text) {
            setShowSpinner(true);
            if (itemLoadWhitoutConvert) {
                LoadList(text)
            }
            else {
                setSerch(text);
                setShowSpinner(true);
            }
        }
    }
    const onChangeItem = async (id, convertItem) => {
        setItemSave(prevEedit => ({
            ...prevEedit,
            convertItemId: id,
            convertItem: convertItem
        }));
        let dataload = itemLoad.data.filter(x => x.id != id);
        setItemListConverted(dataload);
    }
    const [showErrors, setShowErrors] = useState({
        convertItem: undefined
    });
    const onShowError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            convertItem: `El campo es obligatorio`
        }));
    };
    const onCleanError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            convertItem: undefined
        }));
    };
    const onBlur = (event) => {
        let text = event.target.value;
        if (text && itemSave.convertItemId===undefined) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                convertItem: `El artículo no fue encontrado`
            }));
            event?.target?.focus();
        }
        else if (itemSave.convertItemId) {
            onCleanError();
        }
        else {
            onShowError();
            event?.target?.focus();
        }
    };
    useEffect(() => {
        if (itemSave?.convertedItem) {
            if (itemSave?.convertedItem.length > 0) {
                setDisabledComboBox(true);
            }
            else {
                setDisabledComboBox(false);
            }
        }
    }, [itemSave?.convertedItem]);
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información" >
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCombobox
                                                    showSpinner={showSpinner}
                                                    title='Artículo a convertir'
                                                    items={itemList}
                                                    onChangeItemText={OnChangeText}
                                                    selected={itemSave?.convertItemId}
                                                    value={itemSave?.convertItem}
                                                    onChangeItem={onChangeItem} required focus
                                                    validationMessage={showErrors.convertItem}
                                                    onShowError={onShowError} onBlur={onBlur} onCleanError={onCleanError}
                                                    disabledItem={disabledComboBox} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-md-auto'>
                                    <OmniCard title='Conversión'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <AddItem description={itemSave}
                                                    setDescription={setItemSave}
                                                    itemList={itemListConverted}
                                                    setItemList={setItemListConverted}
                                                    titleColum="Lista de artículos" property="convertedItem"
                                                    titleComboBox="Artículos en que se puede convertir" />
                                            </div>
                                        </div>
                                    </OmniCard>

                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
              
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rigthCommandBar'}
                />
            }
        </>
    )
}
