import { Pivot, PivotItem } from '@fluentui/react';
import { useState, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useIdSelectorContext, useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider'
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/WarehouseLocations/Edit';
import { DangerMessage, SuccesMessage } from '../../../Models/Shared/BarMessages/Messages';
import Detail from './Detail';
import { GetToAutocomplete, SetAxiosPrivate as SetAxiosPrivateWarehouses} from '../../../Functions/Catalogs/Warehouse/WarehouseStore'
import { GetByIdAsync, UpdateAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/WarehouseLocations/WarehouseLocationStore';
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/WarehouseLocation.svg').default
}

export default function Edit() {
    const { register, handleSubmit, getValues } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton } = useGeneralStatesContext();
    const id = useIdSelectorContext();
    const axiosPrivate = useAxiosPrivate();
    const changeComponent = useSetStatesContext();
    const [warehouseLocation, setWarehouseLocation] = useState([]);
    const [warehouses,setWarehouses]=useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [warehouseLoad, setWarehouseLoad] = useState();
    const [serchClient, setSerchClientr] = useState('');
    const [warehouseName, setWarehouseName]=useState('');
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateWarehouses(axiosPrivate);

    const [openCallout, setOpenCallout] = useState();
    const submit = async () => {
        setOpenCallout(true);
    };


    const getById = async () => {
        let result = await GetByIdAsync(id);
        if (result.isSuccessful) {
            return result.data;
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const update = async () => {
        if (warehouseLocation === undefined || warehouseLocation.warehouseId === undefined) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Debe seleccionar un almacen")]);
        }
        else {
            var data = getValues();
            data.warehouseId =  warehouseLocation.warehouseId;
            let result = await UpdateAsync(id, data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, id);
                data.id = id;
                updateItem({ ...data, acronym: localStorage.getItem("userName") });
                setListMessageCatalog(message => [...message, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    const GetWarehouses = async()=>{
        let result = await GetToAutocomplete();
        if (result.isSuccessful) {
            var warehouses= result.data.map((item) => ({
                key: item.id ?? item.id,
                value: item.id,
                text: item.name
            }));
            setWarehouses(warehouses);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    const onChangeItemWarehouse = async (id, warehouseLocation) => {
        setWarehouseLocation(prevEedit => ({
            ...prevEedit,
            warehouseId: id,
            warehouseLocation: warehouseLocation
        }));
    }

    const OnChangeWarehouseText = async (text) => {
        if (warehouseLoad) {
            ChangeText(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }

    function ChangeText(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = warehouseLoad.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.text.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                setWarehouses(filteredData);
            }
        }
    }

    useEffect(() => {
        GetWarehouses();
        const fillData = async () => {
            try {
                const data = await getById();
                setWarehouseLocation({
                    warehouseName: data?.warehouseName,
                    warehouseId: data?.warehouseId,
                    location:data?.location
                });
                setLoadingSkeleton(false);
            } catch (error) {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage("Error al obtener datos", error)]);
            }
        }
        setLoadingSkeleton(true);
        fillData();
    }, [])
    useEffect(() => {
        if (warehouses && warehouseLocation) {
            setWarehouses(prevWarehouses => [
                ...prevWarehouses,
                {
                    key: warehouseLocation.warehouseId,
                    value: warehouseLocation.warehouseId,
                    text: warehouseLocation.warehouseName
                }
            ]);
        }
        
    }, [warehouseLocation])
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client" >
                    <PivotItem headerText="Información" itemKey="information" >
                        <div className='container-fluid container-scroll-two-bars'>
                    <div className='row'>
                        <div className='col-md-auto'>
                            <OmniCard title='Datos generales'>
                                <div className='row'>
                                    {
                                        warehouseLocation.length !== 0 &&
                                        (
                                            <>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                    <OmniCombobox
                                                        title='Almacen'
                                                        items={warehouses}
                                                        selected={warehouseLocation?.warehouseId}
                                                        value={warehouseLocation?.warehouseName}
                                                        onChangeItem={onChangeItemWarehouse} required 
                                                        onChangeItemText={OnChangeWarehouseText} 
                                                        image={iconCatalog}/>
                                                    
                                                    </div>
                                                    
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-md-12'>
                                                        <OmniTextBox title='Ubicación' maxLength={16}  register={{ ...register('location') }} value={warehouseLocation.location} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </OmniCard>
                        </div>
                    </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={update}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}

