import { useForm } from "react-hook-form";
import Detail from "./Detail";
import BarButtons from "../../Shared/BarButtons/BarButtons";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { leftButtons, rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Vehicles/Add";
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/Vehicles/VehiclesStore'
import { useGeneralStatesContext, useSetStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { DangerMessage, SuccesMessage } from "../../../Models/Shared/BarMessages/Messages";
import useAxiosPrivate from "../../../Hooks/Common/useAxiosPrivate";
import { OmniTextBox } from "../../Shared/Controls/TextBox/OmniTextBox";
import { Pivot, PivotItem } from '@fluentui/react';
import { OmniCheckBox } from '../../Shared/Controls/Checkbox/OmniCheckBox'
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { useState } from 'react';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Add() {
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [openCallout, setOpenCallout] = useState();
    SetAxiosPrivate(axiosPrivate);
    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            var data = getValues();
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = result.data;
                addNewItem({ ...data, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    const [showErrors, setShowErrors] = useState({
        tons: undefined,
        cubicMeters: undefined,
        vehicleGrossWeight: undefined
    });
    const onBlurTons = (event) => {
        const text = event.target.value;
        if (parseFloat(text) <= 60) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                tons: undefined
            }));
            setValue('tons', text);
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                tons: `El campo toneladas no puede ser mayor a 60`
            }));
            event?.target?.focus();
        }
    };
    const onBlurCubicMeters = (event) => {
        const text = event.target.value;
        if (text) {
            if (parseInt(text) <= 100) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    cubicMeters: undefined
                }));
                setValue('cubicMeters', text);
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    cubicMeters: `El campo metros cúbicos no puede ser mayor a 100`
                }));
                event?.target?.focus();
            }
        }
    };
    const onBlurVehicleGrossWeight = (event) => {
        const text = event.target.value;
        if (parseFloat(text.replace(',', '')) < 10000) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                vehicleGrossWeight: undefined
            }));
            setValue('vehicleGrossWeight', text);
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                vehicleGrossWeight: `El campo peso bruto vehícular no pueden ser mayor a 9,999`
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons rightButtons={rightButtons}
                    leftButtons={leftButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información" >
                        <div className='container-fluid container-scroll'>
                            <div className='row'>
                                <div className='col-md-auto'>
                                    <OmniCard title='Datos generales'>
                                        {
                                            useForeignKey &&
                                            <OmniTextBox required inputType="number" length="10" title='Clave SAP' register={{
                                                ...register('id')
                                            }} focus />
                                        }
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox required title="Alias" register={{ ...register("alias") }} focus={useForeignKey ? false : true} maxLength={128} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox required title="Marca" register={{ ...register("brand") }} maxLength={128} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6'>
                                                <OmniTextBox required title="Modelo" register={{ ...register("model") }} maxLength={128} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox inputType="number" length="4" title="Año" register={{ ...register("year") }} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox title="Color" register={{ ...register("color") }} maxLength={16} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox title="Placa" register={{ ...register("licensePlate") }} maxLength={7} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox inputType={'decimal'}
                                                    length={4}
                                                    decimals={2} title="Toneladas" register={{ ...register("tons") }} validationMessage={showErrors.tons}
                                                    blur={onBlurTons}/>
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniTextBox inputType="number" length="3" title="Metros cúbicos" register={{ ...register("cubicMeters") }} validationMessage={showErrors.cubicMeters}
                                                    blur={onBlurCubicMeters} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox inputType={'decimal'}
                                                    length={6}
                                                    decimals={2} title="Peso bruto vehícular" register={{ ...register("vehicleGrossWeight") }} validationMessage={showErrors.vehicleGrossWeight}
                                                    blur={onBlurVehicleGrossWeight} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-md-auto'>
                                    <OmniCard title='Permisos'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCheckBox title="Requiere permiso para transporte privado"
                                                    register={{ ...register("requiresPrivateTransportPermit") }} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title="Permiso Secretaría de Comunicaciones y Transportes" register={{ ...register("sCTPermission") }} maxLength={16} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title="Número de permiso" register={{ ...register("sCTNumberPermission") }} maxLength={50} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                    <OmniCard title='Aseguradora'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-6'>
                                                <OmniTextBox title="Empresa aseguradora" register={{ ...register("insuranceCarrier") }} maxLength={50} />

                                            </div>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title="Número de póliza" register={{ ...register("policyNumber") }} maxLength={30} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rigthCommandBar'}
                />
            }
        </>
    )
}
