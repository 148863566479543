import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Routes/Edit';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext, useIdSelectorContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { UpdateAsync, SetAxiosPrivate, GetByIdAsync, GetUser, generateObject } from '../../../Functions/Catalogs/Routes/RoutesStore';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { Pivot, PivotItem } from "@fluentui/react";
import { OmniCard } from "../../Shared/Card/OmniCard";
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniColorPicker } from '../../Shared/Controls/ColorPicker/OmniColorPicker';
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Routes.svg').default
}
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export default function Edit() {

    const id = useIdSelectorContext();
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, updateItem, setLoadingSkeleton, updateItemStatus } = useGeneralStatesContext();
    const [userLoad, setUserLoad] = useState();
    const [user, setUser] = useState();
    const [serchClient, setSerchClientr] = useState('');
    const [route, setRoute] = useState();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    const [isItemDisabled, setIsItemDisabled] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const [buttonsClientRoutes, setButtonsClientRoutes]
        = useState({ leftButtons: leftButtons, rightButtons: rightButtons });

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!route.sellerId) {
            errorMessages.push("El vendedor es obligatorio");
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            var data = route;
            let result = await UpdateAsync(id, data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                data.id = id;
                data.name = route.name + " - " + route.seller;
                updateItem({ ...data, updateUserName: localStorage.getItem("userName") });
                if (!data.active) {
                    updateItemStatus(data);
                }
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    };
    const onChangeItemUser = async (id, seller) => {
        setRoute(prevEedit => ({
            ...prevEedit,
            sellerId: id,
            seller: seller
        }));
    }
    const OnLoadUser = async () => {
        let result = await GetUser();
        if (result.isSuccessful) {
            const userList = result.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name,
                disabled: item.route,
                errorTooltip: "Asignado a: " + item.nameRoute
            }));
            setUserLoad(userList)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const getById = async () => {
        setLoadingSkeleton(true);
        let result = await GetByIdAsync(id, axiosPrivate);
        if (result.isSuccessful) {
            setRoute({
                name: result.data.name,
                color: result.data.color,
                sellerId: result.data.sellerId,
                seller: result.data.seller,
                mondayName: result.data.mondayName,
                tuesdayName: result.data.tuesdayName,
                wednesdayName: result.data.wednesdayName,
                thursdayName: result.data.thursdayName,
                fridayName: result.data.fridayName,
                saturdayName: result.data.saturdayName,
                sundayName: result.data.sundayName,
                mondayList: generateObject(result.data?.mondayList),
                tuesdayList: generateObject(result.data?.tuesdayList),
                wednesdayList: generateObject(result.data?.wednesdayList),
                thursdayList: generateObject(result.data?.thursdayList),
                fridayList: generateObject(result.data?.fridayList),
                saturdayList: generateObject(result.data?.saturdayList),
                sundayList: generateObject(result.data?.sundayList),
                active: result.data.active
            });
            setLoadingSkeleton(false);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnLoadUser();
        getById();
    }, []);
    useEffect(() => {
        if (route?.sellerId) {
            setUser([{
                key: route.sellerId,
                value: route.sellerId,
                text: route.seller
            }]);
        }
    }, [route]);
   
    const pivotItemButtonProps = {
        disabled: isItemDisabled,
        style: { color: isItemDisabled ? 'var(--secondary)' : 'var(--dark)' }
    }; const OnChangeUserText = async (text) => {
        if (userLoad) {
            ChangeText(text)
        }
        else {
            setSerchClientr(text);
            setShowSpinner(true);
        }
    }

    function ChangeText(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = userLoad.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.text.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                setUser(filteredData);
            }
        }
        
    }
    useEffect(() => {
        if (userLoad && route?.sellerId) {
            ChangeText(route?.seller);
        }
    }, [userLoad]);
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        seller: undefined
    });
    const onBlurName = (event) => {
        const text = event.target.value;
        if (text) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                name: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                name: `El campo es obligatorio`
            }));
            event?.target?.focus();
        }
    };
    const onShowError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            seller: `El campo es obligatorio`
        }));
    };
    const onCleanError = () => {
        setShowErrors(prevErrors => ({
            ...prevErrors,
            seller: undefined
        }));
    };
    const onBlurSeller = (event) => {
        if (route.sellerId) {
            onCleanError();
        }
        else {
            onShowError();
            event?.target?.focus();
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons rightButtons={buttonsClientRoutes.rightButtons}
                    leftButtons={buttonsClientRoutes.leftButtons} />
                <Pivot className="pivot-center-client" overflowBehavior="menu" styles={pivotStyles}
                >
                    <PivotItem headerText="Información" headerButtonProps={pivotItemButtonProps}>
                        <div className='container-fluid container-scroll-two-bars'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Datos generales'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox required title='Nombre de la ruta'
                                                    focus={route?.sellerId == undefined ? false : true} property="name"
                                                    edit={setRoute} validationMessage={showErrors.name}
                                                    blur={onBlurName} value={route?.name} maxLength={50} />
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <OmniColorPicker title='Color asignado'
                                                    property="color"
                                                    edit={setRoute}
                                                    blur={true}
                                                    value={route?.color} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el lunes'
                                                    property="mondayName"
                                                    edit={setRoute} blur={true} value={route?.mondayName} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el martes'
                                                    property="tuesdayName"
                                                    edit={setRoute} blur={true} value={route?.tuesdayName} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el miércoles'
                                                    property="wednesdayName"
                                                    edit={setRoute} blur={true} value={route?.wednesdayName} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el jueves'
                                                    property="thursdayName"
                                                    edit={setRoute} blur={true} value={route?.thursdayName} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el viernes'
                                                    property="fridayName"
                                                    edit={setRoute} blur={true} value={route?.fridayName} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el sábado'
                                                    property="saturdayName"
                                                    edit={setRoute} blur={true} value={route?.saturdayName} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniTextBox title='Nombre de la ruta para el domingo'
                                                    property="sundayName"
                                                    edit={setRoute} blur={true} value={route?.sundayName} maxLength={50} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <OmniCard title='Vendedor de ruta asignado'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12'>
                                                <OmniCombobox
                                                    title='Nombre del vendedor de ruta'
                                                    items={user}
                                                    selected={route?.sellerId}
                                                    value={route?.seller}
                                                    onChangeItem={onChangeItemUser} required showSpinner={showSpinner}
                                                    onChangeItemText={OnChangeUserText} validationMessage={showErrors.seller}
                                                    onShowError={onShowError} onBlur={onBlurSeller} onCleanError={onCleanError}
                                                    validation={true} image={iconCatalog} focus={route?.sellerId == undefined ? true : false} />
                                            </div>
                                        </div>
                                    </OmniCard>
                                </div>
                            </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Lunes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Martes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Miércoles" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Jueves" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Viernes" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Sábado" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Domingo" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={AddConfirm}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'No'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    )
}
