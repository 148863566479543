import ItemsSelector from "../../Shared/ItemsSelector/ItemsSelector";
import Detail from "./Detail";
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Vehicles/Vehicles"
import { ItemSelectorProvider } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatusUrl = '/vehicles/GetByStatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Vehicle.svg').default
}
export default function Vehicles() {
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de vehículos"}
                    image={iconCatalog}
                    urlGetItems={getByStatusUrl}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"alias"}
                    omniAcronym={"updateUserName"}
                    getInLoad={false}
                    indexComponent={<Index />}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}