import BarButtons from '../../Shared/BarButtons/BarButtons';
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Customers/Customer"
import { Pivot, PivotItem } from "@fluentui/react";
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
const pivotStyles = {
    root: {
        width: '95%'
    }
};
export const Index = (props) => {
    const { extendedAccounting } = useAccountingConfigurationContext();
    return (
        <>
            <BarButtons rightButtons={rightButtons} />
            <Pivot className="pivot-center-client"
                selectedKey={null} overflowBehavior="menu" styles={pivotStyles}>
                <PivotItem headerText="Información" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }} >

                </PivotItem>
                <PivotItem headerText="Facturación" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Contacto" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Crédito" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Venta en ruta" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Direcciones de envío" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                <PivotItem headerText="Ubicaciones" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                {
                    extendedAccounting &&
                    <PivotItem headerText="Contabilidad" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                }
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
            </Pivot>
        </>
    )
}
