import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniCombobox } from '../../../Shared/Controls/ComboBox/OmniCombobox';
import { OmniTextBox } from '../../../Shared/Controls/TextBox/OmniTextBox';
import { DangerMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { useState, useEffect } from 'react';
import {
    GetColoniesbyMunicipalityId, GetContries, GetLocalities,
    GetMunicipalitiesStateId, GetStates,GetColoniesByPostalCode,GetPostalCodesByClue
} from '../../../../Functions/Catalogs/Customers/CustomerStore'
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
export const Domicile = (props) => {
    const { setListMessageCatalog } = useGeneralStatesContext();
    const [contries, setcontries] = useState();
    const [states, setstates] = useState();
    const [municipalities, setmunicipalities] = useState();
    const [localities, setlocalities] = useState();
    const [colonies, setcolonies] = useState();
    const [municipalitiesLoad, setmunicipalitiesLoad] = useState();
    const [localitiesLoad, setlocalitiesLoad] = useState();
    const [coloniesLoad, setcoloniesLoad] = useState();
    const [statesLoad, setstatesLoad] = useState();
    const [showSpinner, setShowSpinner] = useState({
        colony: false,
        state: false,
        municipality: false
    });
    const ListGetStatesLoad = async (contryId) => {
        let result = await GetStates();
        if (result.isSuccessful) {
            setstatesLoad(result.data);
            const filteredData = result.data.filter(item => item.contryId == contryId);
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name,
                    clue: item.clue,
                }));
                setstates(resultList);
            }
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnChangeMunicipalityText = async (text) => {
        if (municipalitiesLoad) {
            const filteredData = municipalitiesLoad.data.filter(item =>
                (text && item.name
                    .toLowerCase()
                    .includes(text.toLowerCase())));
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })).slice(0, 100);
                setmunicipalities(resultList);
            }
            setShowSpinner(prev => ({
                ...prev,
                municipality: false
            }));
        }
        else {
            setShowSpinner(prev => ({
                ...prev,
                municipality: true
            }));
        }
    }
    function ColoniesChange(text) {
        let filteredData = coloniesLoad.data.filter(item =>
            (!props.client.postalCode || (props.client.postalCode && item.postalCode == props.client.postalCode)) &&
            ((props.client.stateId && item.stateId
                == props.client.stateId)) &&
            (props.client.municipalityId
                && item.municipalityId == props.client.municipalityId) &&
            (!text || (text && item.name
                .toLowerCase()
                .includes(text.toLowerCase())))
        );
        if (filteredData.length > 0) {
            const resultList = filteredData.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name
            })).slice(0, 50);
            setcolonies(resultList);
        }
        else {
            filteredData = coloniesLoad.data.filter(item =>
                ((props.client.stateId && item.stateId
                    == props.client.stateId)) &&
                (props.client.municipalityId
                    && item.municipalityId == props.client.municipalityId) &&
                (!text || (text && item.name
                    .toLowerCase()
                    .includes(text.toLowerCase())))
            );
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })).slice(0, 50);
                setcolonies(resultList);
            }
        }
        setShowSpinner(prev => ({
            ...prev,
            colony: false
        }));
    }
    const OnChangeColonyText = async (text) => {
        if (coloniesLoad) {
            ColoniesChange(text);
        }
        else {
            setShowSpinner(prev => ({
                ...prev,
                colony: true
            }));
        }
    }
    const ListGetLocation = async (stateId, state, valuesDefault) => {
        if (statesLoad) {
            let stateClue = statesLoad.filter(item => item.id == stateId)[0].clue;
            let foundlocalities = false;
            if (stateClue) {
                const filteredData = localitiesLoad.data.filter(item =>
                    item.stateClue == stateClue
                );
                if (filteredData.length > 0) {
                    const resultList = filteredData.map((item) => ({
                        key: item.id,
                        value: item.id,
                        text: item.name
                    }));
                    setlocalities(resultList);
                    foundlocalities = true;
                }
            }
            if (!valuesDefault) {
                props.setClient(prevEedit => ({
                    ...prevEedit,
                    municipality: undefined,
                    municipalityId: undefined,
                    colony: undefined,
                    colonyId: undefined,
                    location: undefined,
                    locationId: undefined,
                    stateId: stateId,
                    state: state,
                    postalCode:undefined
                }));
                setmunicipalities(undefined);
                setmunicipalitiesLoad(undefined);
                OnLoadMunicipalities(stateId);
                if (!foundlocalities) {
                    setlocalities(undefined);
                }
            }
        }
    }
    const OnLoadLocalities = async () => {
        let result = await GetLocalities();
        if (result.isSuccessful) {
            setlocalitiesLoad(result)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadColonies = async (municipalityId) => {
        let result = await GetColoniesbyMunicipalityId(municipalityId);
        if (result.isSuccessful) {
            setcoloniesLoad(result)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadMunicipalities = async (stateId) => {
        let result = await GetMunicipalitiesStateId(stateId);
        if (result.isSuccessful) {
            setmunicipalitiesLoad(result)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    const OnLoadDescripcion = async (url, set) => {
        let result = await url();
        if (result.isSuccessful) {
            const resultList = result.data.map((item) => ({
                key: item.clue,
                value: item.id,
                text: item.description
            }));
            set(resultList)
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        ListGetStatesLoad(props.client.contryId);
        if (props.client?.municipalityId) {
            OnLoadColonies(props.client?.municipalityId);
        }
        if (props.client?.stateId) {
            OnLoadMunicipalities(props.client?.stateId);
        }
        OnLoadLocalities();
        OnLoadDescripcion(GetContries, setcontries);
    }, []);
    useEffect(() => {
        if (municipalitiesLoad) {
            const resultList = municipalitiesLoad.data.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name
            })).slice(0, 100);
            setmunicipalities(resultList);
            setShowSpinner(prev => ({
                ...prev,
                municipality: false
            }));
        }
    }, [municipalitiesLoad]);
    useEffect(() => {
        if (coloniesLoad) {
            ColoniesChange('');
        }
    }, [coloniesLoad]);
    useEffect(() => {
        if (props.client?.stateId) {
            ListGetLocation(props.client?.stateId, props.client?.state, true)
        }
    }, [localitiesLoad]);
    const onBlurPostalCode = async (event) => {
        let postalcodeItem = event.target.value;
        if (postalcodeItem != props.client.postalCode) {
            if (postalcodeItem.length == 5 || postalcodeItem.length == 0) {
                if (postalcodeItem.length == 5) {
                    setShowSpinner({
                        colony: true,
                        state: true,
                        municipality: true
                    });
                    let result = await GetPostalCodesByClue(postalcodeItem);
                    if (result.isSuccessful) {
                        await GetColoniesByPostal(result);
                    }
                    else {
                        setListMessageCatalog(message => [...message, new DangerMessage("Código postal invalido")]);
                    }
                }
                else {
                    props.setClient(prevEedit => ({
                        ...prevEedit,
                        municipality: undefined,
                        municipalityId: undefined,
                        state: undefined,
                        stateId: undefined,
                        colony: undefined,
                        colonyId: undefined,
                        location: undefined,
                        locationId: undefined
                    }));
                    event.target.value = '';
                    setmunicipalities(undefined);
                    setcolonies(undefined);
                    setlocalities(undefined);
                }
            }
            else {
                setListMessageCatalog(message => [...message, new DangerMessage("Código postal invalido")]);
                props.setClient(prevEedit => ({
                    ...prevEedit,
                    municipality: undefined,
                    municipalityId: undefined,
                    state: undefined,
                    stateId: undefined,
                    colony: undefined,
                    colonyId: undefined,
                    location: undefined,
                    locationId: undefined,
                    postalCode:undefined
                }));
                setmunicipalities(undefined);
                setcolonies(undefined);
                setlocalities(undefined);
            }
        }
    };
    const processColonyData = (colonyData) => {
        const resultList = colonyData.map((item) => ({
            key: item.id,
            value: item.id,
            text: item.name,
        })).slice(0, 100);
        return resultList;
    };

    
    const GetColoniesByPostal = async (result) => {
        try {
            let resultColony = await GetColoniesByPostalCode(result.data.clue);
            if (resultColony.isSuccessful && resultColony.data && resultColony.data.length > 0) {
                let municipalities = await GetMunicipalitiesStateId(resultColony.data[0].stateId);
                if (municipalities.isSuccessful) {
                    let municipalyCurrent = municipalities.data.find(item => item.id === resultColony.data[0].municipalityId);
                    if (municipalyCurrent) {
                        const municipalitytList = municipalities.data.map((item) => ({
                            key: item.id,
                            value: item.id,
                            text: item.name,
                        }));
                        let stateCurrent = states.find(item => item.value === resultColony.data[0].stateId);
                        setcolonies(processColonyData(resultColony.data));
                        setmunicipalities(municipalitytList);
                        props.setClient(prevEedit => ({
                            ...prevEedit,
                            municipality: municipalyCurrent.name,
                            municipalityId: municipalyCurrent.id.toString(),
                            state: stateCurrent.text,
                            stateId: stateCurrent.value.toString(),
                            colonyId: undefined,
                            colony: undefined,
                            postalCode: result.data.clue,
                        }));
                        setShowSpinner({ colony: false, state: false, municipality: false });
                        ListGetLocation(stateCurrent.value, stateCurrent.text, true);
                        return;
                    }
                }
            }
            else {
                setShowSpinner({ colony: false, state: false, municipality: false });
            }
            props.setClient(prevEedit => ({
                ...prevEedit,
                postalCode: result.data.clue,
            }));
        } catch (error) {
            props.setClient(prevEedit => ({
                ...prevEedit,
                postalCode: result.data.clue,
            }));
        }
    };
    const onChangeItemColony = (colonyId, colony) => {
        if (coloniesLoad) {
            let postalcodeItem = coloniesLoad.data.filter(item => item.id == colonyId)[0].postalCode;
            props.setClient(prevEedit => ({
                ...prevEedit, postalCode: postalcodeItem,
                colony: colony,
                colonyId: colonyId,
            }));
        }
        else {
            props.setClient(prevEedit => ({
                ...prevEedit,
                colony: colony,
                colonyId: colonyId,
            }));
        }
    }
    const onChangeItemMunicipality = async (municipalityId, municipality) => {
        props.setClient(prevEedit => ({
            ...prevEedit,
            municipality: municipality,
            municipalityId: municipalityId,
            colony: undefined,
            colonyId: undefined,
        }));
        setcolonies(undefined);
        setcoloniesLoad(undefined);
        OnLoadColonies(municipalityId);
    }
    const onChangeItemLocation = async (locationId, location) => {
        props.setClient(prevEedit => ({
            ...prevEedit,
            location: location,
            locationId: locationId,
        }));
    }
    const onChangeItemContry = async (contryId, contry) => {
        const filteredData = statesLoad.filter(item => item.contryId == contryId);
        if (filteredData.length > 0) {
            const resultList = filteredData.map((item) => ({
                key: item.id,
                value: item.id,
                text: item.name,
                clue: item.clue,
            }));
            setstates(resultList);
        }
        else {
            setstates(undefined);
        }
        props.setClient(prevEedit => ({
            ...prevEedit,
            municipality: undefined,
            municipalityId: undefined,
            state: undefined,
            stateId: undefined,
            colony: undefined,
            colonyId: undefined,
            location: undefined,
            locationId: undefined,
            contryId: contryId,
            contry: contry
        }));
        setmunicipalities(undefined);
        setcolonies(undefined);
        setlocalities(undefined);
    }
    const onBlurStreet = async (event) => {
        props.setClient(prevEedit => ({
            ...prevEedit,
            street: event.target.value
        }));
    }
    const onBlurOutdoorNumber = async (event) => {
        props.setClient(prevEedit => ({
            ...prevEedit,
            outdoorNumber: event.target.value
        }));
    }
    const onBlurInteriorNumber = async (event) => {
        props.setClient(prevEedit => ({
            ...prevEedit,
            interiorNumber: event.target.value
        }));
    }
    return (
        <>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <OmniTextBox focus={props.focus}  title="Código Postal"
                        value={props.client.postalCode}
                        customMask={"99999"} blur={onBlurPostalCode} />
                </div>
            </div>

            <div className='row'>
                <div className='col-lg-4 col-md-12'>
                    <OmniCombobox
                        title='País'
                        items={contries}
                        selected={props.client?.contryId}
                        value={props.client?.contry}
                        onChangeItem={onChangeItemContry}
                        className="combo-omni-small"                    />
                </div>
                <div className='col-lg-2 col-md-12'>
                </div>
                    <div className='col-lg-4 col-md-12'>
                    <OmniCombobox
                        title='Estado'
                        items={states}
                        selected={props.client?.stateId}
                        value={props.client?.state}
                        onChangeItem={ListGetLocation}
                        className="combo-omni-small"
                        showSpinner={showSpinner.state}                    />
                          </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <OmniCombobox
                        title='Municipio'
                        items={municipalities}
                        onChangeItemText={OnChangeMunicipalityText}
                        selected={props.client?.municipalityId}
                        value={props.client?.municipality}
                        onChangeItem={onChangeItemMunicipality}
                        showSpinner={showSpinner.municipality} />
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <OmniCombobox
                        title='Colonia'
                        items={colonies}
                        onChangeItemText={OnChangeColonyText}
                        selected={props.client?.colonyId}
                        value={props.client?.colony}
                        onChangeItem={onChangeItemColony}
                        showSpinner={showSpinner.colony} />
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <OmniCombobox
                        title='Localidad'
                        items={localities}
                        selected={props.client?.locationId}
                        value={props.client?.location}
                        onChangeItem={onChangeItemLocation} />
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <OmniTextBox title="Calle" value={props.client?.street} blur={onBlurStreet} maxLength={256} />
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <OmniTextBox title="Número exterior" value={props.client?.outdoorNumber}
                            blur={onBlurOutdoorNumber} maxLength={10} />
                    </div>
                        <div className='col-lg-6 col-md-12'>
                    <OmniTextBox title="Número interior" value={props.client?.interiorNumber}
                            blur={onBlurInteriorNumber} maxLength={10} />
                    </div>
            </div>
        </>
    )
}