import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/IepsTax/Ieps';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { AccountConfigurationProvider } from '../../../Context/Account/AccountConfigurationProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatus = '/ieps/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Ieps.svg').default
}
export default function WarehouseLocation() {
    return (
        <ItemSelectorProvider>
            <AccountConfigurationProvider>
                <FieldChangeWatcherProvider>
                    <ItemsSelector
                        title={"Catálogo de IEPS"}
                        image={iconCatalog}
                        urlGetItems={getByStatus}
                        defaultButtonsRightBar={rightButtons}
                        detailComponent={<Detail />}
                        searchActiveInRender={false}
                        omniKey={"id"}
                        omniValue={"description"}
                        omniAcronym={"acronym"}
                        getInLoad={false}
                        indexComponent={<Index />}
                    />
                </FieldChangeWatcherProvider>
            </AccountConfigurationProvider>
        </ItemSelectorProvider>
    )
}