import { useEffect, useState } from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/SynchronizerExceptions/Add';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import Detail from './Detail';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { AddAsync, SetAxiosPrivate } from '../../../Functions/Catalogs/SynchronizerExceptions/SynchronizerExceptionStore';
import { SetAxiosPrivate as SetAxiosPrivateUsers} from '../../../Functions/Catalogs/Warehouse/WarehouseStore'
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
import { OmniRadioGroup } from '../../Shared/Controls/Radio/OmniRadioGroup';
import { Add as AddBranches } from '../../Shared/ItemList/Add';
import { GetBranchsAsync } from '../../../Functions/Branchs/BranchStore';
import { GetItems, SetAxiosPrivate as SetAxiosPrivateItems, GetWhitoutConvert } from '../../../Functions/Catalogs/Items/ItemStore';

const pivotStyles = {
    root: {
        width: '95%'
    }
};
const itemsRadio = [
    {
        key: "0",
        value: "0",
        text: "No afectar almacén",
    },
    {
        key: "1",
        value: "1",
        text: "No cambiar el costo",
    }
];

const branchesRadio = [
    {
        key: "1",
        value: "TodasSucursales",
        text: "Todas las sucursales",
    },
    {
        key: "0",
        value: "Seleccionadas",
        text: "Seleccionadas",
    }
];

const iconCatalog = {
    src: require('../../../Assets/Images/Icons/SynchronizerException.svg').default
}
export default function Add() {
    const { register, handleSubmit, getValues } = useForm();
    const { setListMessageCatalog, addNewItem, useForeignKey } = useGeneralStatesContext();
    const changeComponent = useSetStatesContext();
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    SetAxiosPrivateItems(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const[synchronizerException, setSynchronizerException]=useState({exception:0, allBranches:true, cuvikBranchList:[]});
    const[branches,setBranches]=useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const[visibleBranches, setVisibleBranches]=useState(false);
    const [itemLoadWhitoutConvert, setItemLoadWhitoutConvert] = useState();
    const [serch, setSerch] = useState();
    const [itemList, setItemList] = useState();
    const [itemLoad, setItemLoad] = useState();
    const [itemListConverted, setItemListConverted] = useState();
    SetAxiosPrivateUsers(axiosPrivate);
    

    const submit = async () => {
        setOpenCallout(true);
    };
    const AddConfirm = async () => {
        var data = getValues();
        data.CuvikBranchList=synchronizerException.cuvikBranchList.map(item => {
            return {  CuvikId: item.id, CuvikKey: item.description.split("-")[0].trim() };
        });
        data.sku=synchronizerException.sku;
        data.allBranches=visibleBranches?false:true;
        let result = await AddAsync(data, useForeignKey);
        if (result.isSuccessful) {
            changeComponent(<Detail />, result.data);
            data.id = result.data;
            data.name=synchronizerException.itemName;
            addNewItem({id:result.data, item:data.name, acronym: localStorage.getItem("userName") });
            setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    };
    const OnLoadGetItems = async () => {
        let result = await GetItems();
        if (result.isSuccessful) {
            setItemLoad(result);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    
    const getBranches = async () => {
        let result = await GetBranchsAsync();
        if(result.isSuccessful){
            var branchesList= result.data.map((branch) => ({
                key:branch.value,
                id:branch.key,
                description: branch.value.split("#")[1]+" - " + branch.text,
            }));
            setBranches(branchesList);
        }
        else {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
        }
    }

    const onChangeBranch = (event,data) => {
        let typeRfc = data.value;
        if (typeRfc === 'Seleccionadas') {
            setVisibleBranches(true);

        }
        else {
            setVisibleBranches(false);
        }
    };

    function LoadList(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = itemLoadWhitoutConvert.data.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.description.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text: item.description
                })).slice(0, 100);;
                setItemList(resultList);
            }
        }
    }

    const OnChangeText = async (text) => {
        setShowSpinner(true);
        if (itemLoadWhitoutConvert) {
            LoadList(text)
        }
        else {
            setSerch(text);
            setShowSpinner(true);
        }
    }

    const onChangeItem = async (id, convertItem) => {
        setSynchronizerException(prevEedit => ({
            ...prevEedit,
            sku: id,
            itemName:convertItem
        }));
        let dataload = itemLoad.data.filter(x => x.id != id);
        setItemListConverted(dataload);
    }

    const OnLoadGetWhitoutConvert = async () => {
        let result = await GetWhitoutConvert();
        if (result.isSuccessful) {
            setItemLoadWhitoutConvert(result);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }


    useEffect(()=>{
        OnLoadGetItems();
        OnLoadGetWhitoutConvert();
        getBranches();
    },[])

    useEffect(() => {
        if (itemLoadWhitoutConvert) {
            LoadList(serch)
        }
    }, [itemLoadWhitoutConvert]);
    
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
                <Pivot className="pivot-center-client " overflowBehavior="menu" styles={pivotStyles}>
                    <PivotItem headerText="Información">
                        <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>                                   
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                        <OmniCombobox
                                                showSpinner={showSpinner}
                                                title='Descripción'
                                                items={itemList}
                                                onChangeItemText={OnChangeText}
                                                selected={synchronizerException?.sku}
                                                value={synchronizerException?.itemName}
                                                onChangeItem={onChangeItem} required focus
                                                />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Tipo de excepción'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniRadioGroup name="rbtnException"
                                                title="La excepción aplica para"
                                                orientation="horizontal"
                                                items={itemsRadio}
                                                default={synchronizerException.exception}
                                                propertyId="exception"
                                                edit={setSynchronizerException}
                                                register={{ ...register("exception") }} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Sucursales'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniRadioGroup name="rbtnBranch"
                                                title="La excepción aplica a"
                                                orientation="horizontal"
                                                items={branchesRadio}
                                                default={synchronizerException.allBranches}
                                                onChange={onChangeBranch}
                                                propertyId="branch"
                                                edit={setSynchronizerException}
                                                register={{ ...register("branch") }} />
                                        </div>
                                    </div>    
                                    {
                                        visibleBranches
                                        ?
                                            <div className='row'>
                                                <div className='col-lg-12 col-md-12'>
                                                <AddBranches description={synchronizerException}
                                                    setDescription={setSynchronizerException}
                                                    itemList={branches}
                                                    setItemList={setBranches}
                                                    titleColum="Lista de sucursales" property="cuvikBranchList"
                                                    titleComboBox="Sucursal" />
                                                </div>
                                            </div>
                                        :<></>
                                    }
                                </OmniCard>
                            </div>
                        </div>
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Cambios" headerButtonProps={{
                        'disabled': true,
                        'style': { color: 'grey' }
                    }}>
                    </PivotItem>
                </Pivot>
            </form>
            <OmniCallout
                open={openCallout}
                updateIsOpenAlert={setOpenCallout}
                leftFunction={AddConfirm}
                title='Confirmación'
                content='¿Desea guardar los cambios?'
                leftButton={'Si'}
                rightButton={'Regresar'}
                backgroundColorLeft={'var(--success)'}
                colorLeft={'white'}
                backgroundColorRight={'var(--secondary)'}
                colorRight={'white'}
                buttonId={'BtnGuardar'}
                barGroupKey={'rigthCommandBar'}
            />
        </>
    )
}
