import "../../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { useGeneralStatesContext } from "../../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { OmniTextBox } from "../../../Shared/Controls/TextBox/OmniTextBox";
import { DangerMessage, SuccesMessage } from "../../../../Models/Shared/BarMessages/Messages";
import { OmniCard } from "../../../Shared/Card/OmniCard";
import { Add } from '../../../Shared/EmailList/Add';
import { useEffect, useState } from 'react';
import { rightButtons, leftButtons } from "../../../../Assets/Constants/BarButtons/Catalog/Customers/ShippingAddresses/Add";
import { leftButtons as leftButtonsEdit } from "../../../../Assets/Constants/BarButtons/Catalog/Customers/ShippingAddresses/Edit";
import {
    UpdateShippingAddressesAsync, AddShippingAddressesAsync
} from '../../../../Functions/Catalogs/Customers/CustomerStore'
import { Domicile } from '../Domicile/Domicile';
import { DomicileDetail } from '../Domicile/DomicileDetail';
import { PersonRegular } from "@fluentui/react-icons";
import { OmniTextArea } from "../../../Shared/Controls/TextArea/OmniTextArea";
export const ShippingAddresses = (props) => {
   
    const { setListMessageCatalog } = useGeneralStatesContext();
    const itemSelected = (props.view[props.property].viewEdit ? props.description[props.property].find(x => x.id == [props?.itemSelected[props.property]]) : undefined);
    const initialEmailList = {
        contryId: "151",
        contry: "México",
        listShippingAddressesEmail: [],
        longitude: 0,
        latitude: 0
    };
    const [shippingAddresses, setShippingAddresses] = useState(itemSelected ?? initialEmailList)
    const [showErrors, setShowErrors] = useState({
        branchName: undefined,
        shippingReason: undefined,
        managerName: undefined,
        distanceConsignmentNoteSupplement: undefined
    });
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [])
    function Return() {
        props.setView((prevView) => ({
            ...prevView,
            [props.property]: {
                viewAdd: false,
                viewDetail: true,
                viewEdit: false
            }
        }));
    }
    useEffect(() => {
        props.setButtons(prevEmail => ({
            ...prevEmail,
            leftButtonsChild: itemSelected !== undefined ? leftButtonsEdit : leftButtons,
            rightButtonsChild: rightButtons(Save, Return)
        }));
    }, [shippingAddresses])
    const Save = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!shippingAddresses.shippingReason) {
            errorMessages.push("La razón de envío es obligatoria");
        }

        if (!shippingAddresses.managerName) {
            errorMessages.push("El nombre del encargado es obligatorio");
        }
        if (shippingAddresses.listShippingAddressesEmail.length === 0) {
            errorMessages.push("El correo es obligatorio");
        }
        if (shippingAddresses.branchName && errorMessages.includes('El nombre de la sucursal es obligatorio')) {
            const index = errorMessages.indexOf('El nombre de la sucursal es obligatorio');
            if (index !== -1) {
                errorMessages.splice(index, 1);
            }
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            const updatedItem = {
                ...shippingAddresses,
                clientId: props.clientId,
                active: true
            };
            if (itemSelected !== undefined) {
                let result = await UpdateShippingAddressesAsync(updatedItem);
                if (result.isSuccessful) {
                    props.setDescription((prevClient) => {
                        const updatedClient = prevClient[props?.property].map((item) => {
                            if (item.id === props.itemSelected[props.property]) {
                                return updatedItem;
                            }
                            return item;
                        });
                        return {
                            ...prevClient,
                            [props.property]: updatedClient,
                        };
                    });
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
            else {
                let result = await AddShippingAddressesAsync(updatedItem);
                if (result.isSuccessful) {
                    updatedItem.id = result.data;
                    updatedItem.latitude = undefined;
                    updatedItem.longitude = undefined;
                    if (props?.description[props?.property]) {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [...prevClient[props.property], updatedItem],
                        }));
                    }
                    else {
                        props.setDescription((prevClient) => ({
                            ...prevClient,
                            [props.property]: [updatedItem],
                        }));

                    }
                    props.setSelected((prevClient) => ({
                        ...prevClient,
                        [props.property]: result.data
                    }));
                    props.setView((prevView) => ({
                        ...prevView,
                        [props.property]: {
                            viewAdd: false,
                            viewDetail: true,
                            viewEdit: false
                        }
                    }));
                    //setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
                }
                else {
                    setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
                }
            }
        }
    }
    const onBlurBranchName = (event) => {
        ValidText(event, "branchName", "El nombre de la sucursal es obligatorio");
    };
    const onBlurShippingReason = (event) => {
        ValidText(event, "shippingReason", "La razón de envío es obligatoria");
    };
    const onBlurManagerName = (event) => {
        ValidText(event, "managerName", "El nombre del encargado es obligatorio");
    };
    function ValidText(event, property, text) {
        const input = event.target.value;
        if (input) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: text
            }));
            event?.target?.focus();
        }
    }
    const onBlurDistanceConsignmentNoteSupplement = (event) => {
        let text = event.target.value;
        let distance = text.replace(",", "");
        let distanceFloat = parseFloat(distance);
        if (distanceFloat < 99999) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                distanceConsignmentNoteSupplement: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                distanceConsignmentNoteSupplement: 'El campo distancia para el complemento de la carta porte debe ser menor a 99999'
            }));
            event?.target?.focus();
        }
    };
    return (
        <>
            <OmniCard title='Datos generales'>
                <OmniTextBox  required
                    title="Nombre de la sucursal"
                    focus value={shippingAddresses?.branchName} maxLength={32}
                    property="branchName"
                    edit={setShippingAddresses} blur={onBlurBranchName}
                    validationMessage={showErrors.branchName}                />
                <OmniTextBox  title="Razón de envío" required
                    value={shippingAddresses?.shippingReason} maxLength={64}
                    property="shippingReason"
                    edit={setShippingAddresses} blur={onBlurShippingReason}
                    validationMessage={showErrors.shippingReason} />
                <OmniTextBox title="Nombre del encargado" required
                    value={shippingAddresses?.managerName} maxLength={50}
                    property="managerName"
                    edit={setShippingAddresses} blur={onBlurManagerName}
                    validationMessage={showErrors.managerName} contentAfter={<PersonRegular />}             />
                <Add description={shippingAddresses}
                    setDescription={setShippingAddresses}
                    titleColum="Lista de correos" property="listShippingAddressesEmail"
                    titleTextBox="Correo de la sucursal" />
                <OmniTextBox width='48%' title="Teléfono de la sucursal"
                    value={shippingAddresses?.branchPhone} type="number"
                    property="branchPhone" customMask={"999-999-9999"}
                    edit={setShippingAddresses} blur={true}/>
                <OmniTextBox inactivefieldEdited width='48%'
                    title="Distancia para el complemento de la carta porte"
                    property="distanceConsignmentNoteSupplement"
                    edit={setShippingAddresses} 
                    value={shippingAddresses?.distanceConsignmentNoteSupplement} blur={onBlurDistanceConsignmentNoteSupplement}
                    inputType={'decimal'}
                    length={7}
                    decimals={2}
                    validationMessage={showErrors.distanceConsignmentNoteSupplement} />
                    </OmniCard>
            <OmniCard title='Dirección de envío'>
                {shippingAddresses?.defaultAddress === true ?
                    <DomicileDetail itemSelected={itemSelected} />
                    :  <Domicile focus={false} client={shippingAddresses} setClient={setShippingAddresses} />             }
                <OmniTextArea inactivefieldEdited title="Referencias" 
                    value={shippingAddresses?.references} maxLength={2048}
                    property="references"
                    edit={setShippingAddresses} blur={true}/>
            </OmniCard>
        </>
    )
}