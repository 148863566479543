import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import { OmniCard } from "../../Shared/Card/OmniCard";
import { Text } from '@fluentui/react-components';
import '../../../Assets/Styles/Accounting/AccountingAccount/AccountingAccount.css';
export const AccountingAccount = (props) => {
    return (
        <div className='container-fluid container-scroll-two-bars'>
            <div className='row'>
                <div className='col-lg-6 col-md-12'>
                    <OmniCard title='Contabilidad'>
                        <div className='row'>
                            <OmniTextBox
                                {...props}
                                focus
                                required
                                title='Cuenta contable'
                                maxLength={16 - props.baseValue.length}
                                property={"accountingAccount"}
                                regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/}
                                contentBefore={
                                    <Text size={300} id={'accountingAccount'} className={'base-color'} >
                                        {props.baseValue}
                                    </Text>
                                }
                                value={props.value?.startsWith(props.baseValue) ? props.value.substring(props.baseValue.length) : props.value}
                            />
                        </div>
                    </OmniCard>
                </div>
            </div>
        </div>
    );
}