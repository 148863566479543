import { useRef, useState, useEffect, Component } from "react";
import useAuth from '../../Hooks/Authentication/useAuth'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../ApiConector/axios'
import '../../Assets/Styles/Authentication/LoginBox.css'
import { Stack } from '@fluentui/react/lib/Stack';
import { Image } from '@fluentui/react/lib/Image';
import { Label } from '@fluentui/react/lib/Label';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { TextField } from '@fluentui/react/lib/TextField';
/*import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';*/

import {CompoundButton} from "@fluentui/react-components";
import { Identification } from '../Shared/Biometric/HID/Identification'
import { GetAccountingByCustomerId, SetAxiosPrivate } from "../../Functions/Shared/CuvitekNetConnection/CuvitekNetConnectionStore";
import useAxiosPrivate from "../../Hooks/Common/useAxiosPrivate";

const LOGIN_URL = '/authentication/Authenticate';
const version_url = '/configurationversion/getversion';
const omniLogo = require('../../Assets/Images/Authentication/AlixOmniLogo.svg').default;
const cuvitekTitle = require('../../Assets/Images/Authentication/CuvitekTitle.svg').default;
const lockImg = require('../../Assets/Images/Authentication/LockImage.svg').default;
const authIconButton = require('../../Assets/Images/Icons/LockAuth.svg').default;
/*const fingetprintIconButton = require('../../Assets/Images/Icons/Fingerprint.svg').default;*/

function getStyles(props: ITextFieldStyleProps): Partial<ITextFieldStyles> {
    return {
        subComponentStyles: {
            label: getLabelStyles,
        },
    };
}

function getLabelStyles(props: ILabelStyleProps): ILabelStyles {
    return {
        root: {
            color: '#fff',
            fontWeight: 400,
            fontSize: 15
        },
    };
}



export function LoginBox() {
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [versionMessageTitle, setVersionMessageTitle] = useState('');
    const [versionMessage, setVersionMessage] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [executeValidation, setExecuteValidation] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);


    useEffect(() => {
        getVersion();
        if (executeValidation && !isActive) {
            userRef.current.focus();
        }
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);
    
    const loginHuella = async (hidResponse) => {
        if (hidResponse) {
            const accessToken = hidResponse.token;
            const roleId = hidResponse.roleId;
            const userId = hidResponse.id;
            setUser(hidResponse.userName);
            setAuth({ user, pwd, roleId, accessToken, userId });
            localStorage.setItem('fullName', hidResponse.fullName);
            localStorage.setItem('userName', hidResponse.userName);
            localStorage.setItem('rolId', roleId);
            localStorage.setItem('userId', userId);
            localStorage.setItem('useForeignKey', hidResponse.useForeignKey);
            GetAccountingConfigurations();
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ "UserName": user, "Password": pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            //console.log(JSON.stringify(response?.data));
            const accessToken = response?.data?.token;
            const roleId = response?.data?.roleId;
            const userId = response?.data?.id;
            const securePwd = 'XXX'
            setAuth({ user, pwd, roleId, accessToken, userId });
            
            localStorage.setItem('fullName', response?.data?.fullName);
            localStorage.setItem('userName', user);
            localStorage.setItem('rolId', roleId);
            localStorage.setItem('userId', userId);
            localStorage.setItem('useForeignKey', response?.data?.useForeignKey);
            GetAccountingConfigurations();
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password.' + err.response?.data);
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized. ' + err.response?.data);
            } else {
                setErrMsg('Login Failed. '+err.response?.data);
            }
            errRef.current.focus();
        }
    };

    const togglePersist = () => {
        setPersist(prev => !prev);
    };

    const GetAccountingConfigurations= async()=>{
        var configurations= await GetAccountingByCustomerId();
        if(configurations!=null){
            localStorage.setItem("AccountConfigurations", JSON.stringify(configurations.data));
        }
        
    }

    const getVersion = async () => {
        try {
            const response = await axios(version_url);
            if (response.data?.isSuccessful) {
                setIsActive(false);
                setExecuteValidation(true);
            } else {
                setIsActive(true);
                setVersionMessageTitle(response.data?.errorMessage || 'Error');
                setVersionMessage('Por favor contacte a soporte para realizar la actualización');
                setExecuteValidation(true);
            }
            setExecuteValidation(true);
        } catch (err) {
            setIsActive(true);
            setVersionMessageTitle(err.response?.data?.errorMessage || 'Error');
            setVersionMessage('Por favor contacte a soporte.');
            setExecuteValidation(true);
            //console.log(err?.response?.data?.detail);
        }
    }

    useEffect(() => {
        //localStorage.setItem("persist", persist);
        localStorage.setItem("persist", true);
    }, [persist]);


    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            {
                isActive &&
                <Panel isOpen={isActive}
                    isFooterAtBottom={true}
                    className="fingerprint-login-panel"
                    hasCloseButton={false}
                    headerText={versionMessageTitle}
                    type={PanelType.custom}
                    customWidth={'466px'}
                    overlayProps={{ className: 'fingerprint-login-panel-overlay' }}>
                    <div className="login-fingerprint-message">{versionMessage}</div>
                </Panel>}
            <Stack enableScopedSelectors vertical className="login-box-outer" verticalAlign='center' horizontalAlign='center'>
                <Stack.Item grow className="login-box-fields-container">
                    {/*<Image src={omniLogo}  ></Image>*/}
                </Stack.Item>
                <Stack.Item className="login-box-fields-container">
                    <Image src={lockImg}  ></Image>
                </Stack.Item>
                {(executeValidation && !isActive) &&
                    <>
                        <Stack.Item align='start' className="stack-item" >
                            {(executeValidation && !isActive) &&
                                <TextField type="password"
                                    label="Nombre de usuario"
                                    styles={getStyles}
                                    id="username"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                    focus
                                />
                            }
                        </Stack.Item>
                        <Stack.Item align='start' className="stack-item" >
                            {(executeValidation && !isActive) &&
                                <TextField type="password"
                                    label="Contraseña"
                                    canRevealPassword
                                    autoComplete="off"
                                    revealPasswordAriaLabel="Mostrar contraseña"
                                    styles={getStyles}
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                />
                            }
                        </Stack.Item>
                        <Stack.Item align='start' className="caps-lock-container" >
                            {/*<div className="persistCheck">*/}
                            {/*    <input*/}
                            {/*        type="checkbox"*/}
                            {/*        id="persist"*/}
                            {/*        onChange={togglePersist}*/}
                            {/*        checked={persist}*/}
                            {/*    />*/}
                            {/*    <label htmlFor="persist">Mantener sesión iniciada</label>*/}
                            {/*</div>*/}
                            {/*<Label className="lbl lbl-caps-lock" >Mayúsculas activadas</Label>*/}
                        </Stack.Item>
                        <Stack.Item align='start' className="lbl-validation-error-container" >
                            <Label className="lbl lbl-validation-errors" ref={errRef} >{errMsg}</Label>
                        </Stack.Item>
                        <Stack.Item align='start' className="stack-item" >
                            <CompoundButton id="btnNext"
                                type="submit"
                                className="tile-button tile-button-blue"
                                icon={<Image src={authIconButton}  ></Image>}
                                iconPosition="after"
                                shape="square" size="small">Iniciar sesión</CompoundButton>
                        </Stack.Item>
                        <Stack.Item grow align='start' className="stack-item" >
                            {/*<CompoundButton id="btnAuthWithFingerprint" className="tile-button tile-button-red"*/}
                            {/*    icon={<Image src={fingetprintIconButton}  ></Image>}*/}
                            {/*    iconPosition="after"*/}
                            {/*    shape="square" size="small">Usar lector biométrico</CompoundButton>*/}
                            <Identification loginHuella={loginHuella}></Identification>
                        </Stack.Item>
                    </>
                }
                {/*<Stack.Item grow className="stack-item stack-item-buttons">*/}
                {/*    <DefaultButton uniqueId="btnCancel" className="btn btn-dark" text="Cancelar" allowDisabledFocus />*/}
                {/*    &nbsp;&nbsp;*/}
                {/*    <PrimaryButton uniqueId="btnNext" text="Siguiente" allowDisabledFocus className="btn btn-primary" type="submit" />*/}
                {/*</Stack.Item>*/}
                <br />
                <Stack.Item className="stack-item-bottom">
                    <Image src={cuvitekTitle} ></Image>
                </Stack.Item>
            </Stack>
        </form>
    );
}

