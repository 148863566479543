import Tax from './Components/Catalogs/AccountingTaxes/Tax'
import Bank from './Components/Catalogs/Banks/Bank';
import Brand from './Components/Catalogs/Brand/Brand';
import Categories from './Components/Catalogs/Categories/Categories'
import Customer from './Components/Catalogs/Customers/Customer';
import CompanyAsset from './Components/Catalogs/CompanyAssets/CompanyAsset'
import ConvertItem from './Components/Catalogs/ConvertItems/ConvertItem'
import Creditor from "./Components/Catalogs/Creditors/Creditor";
import CreditorAccount from "./Components/Catalogs/CreditorAccount/CreditorAccount";
import CreditorSubAccount from "./Components/Catalogs/CreditorSubAccount/CreditorSubAccount";
import Driver from "./Components/Catalogs/Drivers/Driver";
import Employee from './Components/Catalogs/Employees/Employee';
import MasterPolicy from './Components/Accounting/MasterPolicy/MasterPolicy';
import Measure from './Components/Catalogs/Measures/Measure';
import Family from "./Components/Catalogs/Families/Family";
import Home from "./Components/Home/Home";
import Ieps from "./Components/Catalogs/IepsTax/Ieps"
import Routes from './Components/Catalogs/Routes/Routes';
import Supplier from './Components/Catalogs/Suppliers/Supplier';
import SynchronizerException from './Components/Catalogs/SynchronizerExceptions/SynchronizerException';
import TransferRoute from './Components/Catalogs/TransferRoutes/TransferRoute';
import Variety from './Components/Catalogs/Varieties/Variety';
import Vehicles from './Components/Catalogs/Vehicles/Vehicles';
import Warehouse from './Components/Catalogs/Warehouses/Warehouse';
import WarehouseLocation from './Components/Catalogs/WarehouseLocations/WarehouseLocation';


const AppRoutes = [
    {
        path:'catalogs/accountingtaxes',
        element:<Tax/>
    },
    {
        path: '/catalogs/banks',
        element: <Bank />
    },
    {
        path: '/catalogs/brand',
        element: <Brand />
    },
    {
        path: 'catalogs/customers',
        element: <Customer />
    },
    {
        path: 'catalogs/categories',
        element: <Categories />
    },
    {
        path: 'catalogs/companyasset',
        element: <CompanyAsset />
    },
    {
        path: 'catalogs/convertItems',
        element: <ConvertItem />
    },
    {
        path: 'catalogs/creditor',
        element: <Creditor/>
    },
    {
        path: 'catalogs/creditoraccount',
        element: <CreditorAccount />
    },
    {
        path: 'catalogs/creditorsubaccount',
        element: <CreditorSubAccount />
    },
    {
        path: 'catalogs/driver',
        element: <Driver />
    },
    {
        path: 'catalogs/employees',
        element: <Employee />
    },
    {
        path: 'Accounting/masterpolicy',
        element: <MasterPolicy />
    },
    {
        path: 'catalogs/Measures',
        element: <Measure />
    },
    {
        path: 'catalogs/family',
        element: <Family />
    },
    {
        path: '/',
        element: <Home/>,
    },
    {
        path:'catalogs/ieps',
        element:<Ieps/>
    },
    {
        path: 'catalogs/routes',
        element: <Routes />
    },
    {
        path: 'catalogs/suppliers',
        element: <Supplier />
    },
    {
        path: 'catalogs/synchronizerexception',
        element: <SynchronizerException />
    },
    {
        path: 'catalogs/transferroute',
        element: <TransferRoute />
    },
    {
        path: '/catalogs/variety',
        element:<Variety/>
    },
    {
        path: 'catalogs/vehicles',
        element: <Vehicles />
    },
    {
        path: 'catalogs/warehouse',
        element: <Warehouse />
    },
    {
        path:'catalogs/warehouselocation',
        element:<WarehouseLocation />
    },
];

export default AppRoutes;
