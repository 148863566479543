import { Field, Dropdown, Option } from '@fluentui/react-components';
import '../../../../Assets/Styles/Shared/Controls/OmniDropdown.css'
import '../../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import { useRef, useState, useEffect } from 'react';
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
export const OmniDropdown = (props) => {

    const includeDefaultOption = props?.IncludeDefaultOption === undefined ? true : props.IncludeDefaultOption;
    const fieldWatcher = useFieldChangeWatcherContext();
    const formControlId = props?.register?.name;
    const initialFieldValue = GetInitialSelectedOption();
    const [selectedValue, setSelectedValue] = useState(initialFieldValue);
    const fieldEdited = useRef(false);
    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.value) {
            initialSelectedOpction = props?.value
        }
        else if (includeDefaultOption && includeDefaultOption === true) {
            initialSelectedOpction = "";
        }
        else if (props?.items) {
            initialSelectedOpction = props.items.length > 0 ? props.items[0]?.value : '';
        }
        else {
            initialSelectedOpction = '';
        }
        return initialSelectedOpction;
    };
   
    
    //init control on Context
    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === formControlId) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }

    }, [fieldEdited.current]);
    const [selectedOptions, setSelectedOptions] = useState([
        props?.selected?.toString()
    ]);
    const onOptionSelect: (typeof props)["onOptionSelect"] = (ev, data) => {

        if (data.selectedOptions !== undefined && data.optionText !== undefined) {
            setSelectedOptions(data.selectedOptions);
            setSelectedValue(data.optionText ?? "");
            if (props.edit) {
                props.edit(prevEedit => ({ ...prevEedit, [props.property]: data.optionValue, [props.name]: data.optionText ?? "" }));
            }
            props.onChangeItem && props.onChangeItem(data.optionValue, data.optionText);
            if (initialFieldValue == data.optionText) {
                fieldEdited.current = false;
            }
            else {
                if (!props.inactivefieldEdited) {
                    fieldEdited.current = true;
                }
            }
        }
    };
    useEffect(() => {
        setSelectedValue(props.value ?? '');
    }, [props.value]);
    useEffect(() => {
        if (props.selected) {
            setSelectedOptions([props.selected.toString()]);
        }
    }, [props.selected]);
    return (
        <>
            <div className={'container-controls'} style={{ width: props.width }}>
                <Field className={"label-omni"} validationState={props.validationMessage === undefined ? "none" : "error"}
                    validationMessage={props.validationMessage ?? null} >
                    <OmniFieldLabel {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>
                    <Dropdown
                        {...props}
                        {...props.register}
                        placeholder="Seleccione"
                        appearance="underline"
                        autoFocus={props.focus}
                        className={"dropdown-omni " + props.className}
                        title=""
                        value={selectedValue}
                        selectedOptions={selectedOptions}
                        onOptionSelect={onOptionSelect}
                        listbox={{ className: "list-dropdown-omni"  }}
                    >
                        {props?.items && props?.items.map((option) => (
                            <Option key={option.key} value={option.value.toString()}
                                checkIcon={null}>
                                {option?.icon && option.icon}
                                {option.text}
                            </Option>
                        ))}
                    </Dropdown>
                </Field>
            </div>
        </>
    );
}