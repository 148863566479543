import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Measures/Measure';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatus = '/measure/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Measure.svg').default
}
export default function Measure() {
    return (
        <ItemSelectorProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de medidas"}
                    image={iconCatalog}
                    urlGetItems={getByStatus}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"name"}
                    omniAcronym={"updateUserName"}
                    getInLoad={false}
                    indexComponent={<Index />}
                />
            </FieldChangeWatcherProvider>
        </ItemSelectorProvider>
    )
}