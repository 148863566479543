import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../Hooks/Common/useAxiosPrivate';
import { OmniCard } from '../../Shared/Card/OmniCard';
import { OmniTextBox } from '../../Shared/Controls/TextBox/OmniTextBox';
import BarButtons from '../../Shared/BarButtons/BarButtons';
import { leftButtons, rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Banks/Add';
import { SuccesMessage, DangerMessage } from '../../../Models/Shared/BarMessages/Messages';
import { useGeneralStatesContext, useSetStatesContext } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { useAccountingConfigurationContext } from '../../../Context/Account/AccountConfigurationProvider';
import { Pivot, PivotItem } from '@fluentui/react';
import { AddAsync, SetAxiosPrivate, ValidateKeyAsync, GetBanksSatByActive } from '../../../Functions/Catalogs/Banks/BankStore';
import Detail from './Detail';
import { OmniCombobox } from '../../Shared/Controls/ComboBox/OmniCombobox';
import { OmniCallout } from '../../Shared/Callout/OmniCallout';
export default function Add() {
    const { handleSubmit } = useForm();
    const { setListMessageCatalog, addNewItem } = useGeneralStatesContext();
    const { useAccounting } = useAccountingConfigurationContext();
    const changeComponent = useSetStatesContext();
    const [bank, setBank] = useState();
    const [bankSatList, setBankSatList] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    SetAxiosPrivate(axiosPrivate);
    const [openCallout, setOpenCallout] = useState();
    const [showErrors, setShowErrors] = useState({
        name: undefined,
        accountingAccount: undefined,
        key: undefined
    });
    const submit = async () => {
        setOpenCallout(true);
    };
    const Save = async () => {
        let errorMessages = [];
        for (const property in showErrors) {
            if (Object.prototype.hasOwnProperty.call(showErrors, property)) {
                const errorValue = showErrors[property];
                if (errorValue) {
                    errorMessages.push(errorValue);
                }
            }
        }
        if (!bank.name) {
            errorMessages.push("El nombre obligatorio");
        }
        if (!bank.key) {
            errorMessages.push("La clave es obligatoria");
        }
        if (useAccounting && !bank.accountingAccount) {
            errorMessages.push("La cuenta contable es obligatoria");
        }
        if (errorMessages.length > 0) {
            setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(errorMessages.map(x => x).join('. '))]);
        }
        else {
            let data = bank;
            if (useAccounting) {
                data.accountingAccount = bank.accountingAccount.endsWith(".") ? bank.accountingAccount.slice(0, -1) : bank.accountingAccount;
            }
            data.bankSatId = bank.bankSatId === '0' ? undefined : bank.bankSatId;
            let result = await AddAsync(data);
            if (result.isSuccessful) {
                changeComponent(<Detail />, result.data);
                bank.id = result.data;
                addNewItem({ ...bank, updateUserName: localStorage.getItem("userName") });
                setListMessageCatalog(listMessage => [...listMessage, new SuccesMessage(result.successMessage)]);
            }
            else {
                setListMessageCatalog(listMessage => [...listMessage, new DangerMessage(result.errorMessage)]);
            }
        }
    }
    const onBlurName = (event) => {
        ValidText(event, "name", "El nombre obligatorio");
    };
    const onBlurKey = async (event) => {
        ValidText(event, "key", "La clave es obligatoria");
        const input = event.target.value;
        if (input) {
            let result = await ValidateKeyAsync(input)
            if (result.isSuccessful) {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    key: undefined
                }));
            }
            else {
                setShowErrors(prevErrors => ({
                    ...prevErrors,
                    key: result.errorMessage
                }));
                event?.target?.focus();
            }
        }
    };
    const onBlurAccountingAccount = (event) => {
        ValidText(event, "accountingAccount", "La cuenta contable es obligatoria");
    };
    function ValidText(event, property, text) {
        const input = event.target.value;
        if (input) {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: undefined
            }));
        }
        else {
            setShowErrors(prevErrors => ({
                ...prevErrors,
                [property]: text
            }));
            event?.target?.focus();
        }
    }
    const OnLoadGetBanksSat = async () => {
        let result = await GetBanksSatByActive();
        if (result.isSuccessful) {
            setBankSatList(result.data);
        }
        else {
            setListMessageCatalog(message => [...message, new DangerMessage(result.errorMessage)]);
        }
    }
    useEffect(() => {
        OnLoadGetBanksSat();
    }, [])

    const OnChangeText = async () => {
        setShowSpinner(true);
        if (bankSatList) {
            setShowSpinner(false);
        }
        else {
            setShowSpinner(true);
        }
    }
    const onChangeBankSat = (id) => {
        setBank(prevButtons => ({
            ...prevButtons,
            bankSatId: id
        }));
    }
    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
            <BarButtons leftButtons={leftButtons} rightButtons={rightButtons} />
            <Pivot className="pivot-center-client">
                <PivotItem headerText="Información" itemKey="information" >
                    <div className='container-fluid container-scroll-two-bars'>
                        <div className='row'>
                            <div className='col-md-auto'>
                                <OmniCard title='Datos generales'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <OmniTextBox required title='Clave' maxLength={3} property="key"
                                                edit={setBank} value={bank?.key} focus blur={onBlurKey}
                                                validationMessage={showErrors.key} />
                                        </div>
                                        <div className='col-lg-8 col-md-12'>
                                            <OmniTextBox required title='Nombre' maxLength={64} property="name"
                                                edit={setBank} value={bank?.name} blur={onBlurName}
                                                validationMessage={showErrors.name} />
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                            <div className='col-md-auto'>
                                <OmniCard title='Catálogo de bancos SAT'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12'>
                                            <OmniCombobox
                                                showSpinner={showSpinner}
                                                title='Banco'
                                                items={bankSatList}
                                                onChangeItemText={OnChangeText}
                                                selected={bank?.bankSatId}
                                                value={bank?.bankSat} onChangeItem={onChangeBankSat}/>
                                        </div>
                                    </div>
                                </OmniCard>
                            </div>
                        </div>
                    </div>
                    </PivotItem>
                    {
                        useAccounting &&
                        <PivotItem headerText="Contabilidad">
                            <div className='container-fluid container-scroll-two-bars'>
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <OmniCard title='Datos generales'>
                                            <div className='row'>
                                                <div className='col-lg-6 col-md-12'>
                                                    <OmniTextBox required title='Cuenta contable' maxLength={16} property="accountingAccount"
                                                        edit={setBank} value={bank?.accountingAccount} blur={onBlurAccountingAccount}
                                                        validationMessage={showErrors.accountingAccount} focus regex={/^(?:[0-9]+(?:\.[0-9]*)?)*$/} />
                                                </div>
                                            </div>
                                        </OmniCard>
                                    </div>
                                </div>
                            </div>
                        </PivotItem>
                    }
                <PivotItem headerText="Cambios" headerButtonProps={{
                    'disabled': true,
                    'style': { color: 'grey' }
                }}>
                </PivotItem>
                </Pivot>
            </form>
            {
                <OmniCallout
                    open={openCallout}
                    updateIsOpenAlert={setOpenCallout}
                    leftFunction={Save}
                    title='Confirmación'
                    content='¿Desea guardar los cambios?'
                    leftButton={'Si'}
                    rightButton={'Regresar'}
                    backgroundColorLeft={'var(--success)'}
                    colorLeft={'white'}
                    backgroundColorRight={'var(--secondary)'}
                    colorRight={'white'}
                    buttonId={'BtnGuardar'}
                    barGroupKey={'rightCommandBar'}
                />
            }
        </>
    );
}



