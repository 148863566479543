import Add from "../../../../../Components/Catalogs/SynchronizerExceptions/Add";
export const leftButtons= [ {
    key: 'BtnStatus',
    text: 'Inactivar',
    iconName: 'inactivecuvitek',
    iconColor: 'var(--secondary)',
    iconHover: 'var(--primary)',
    backgroundColorButton: '',
    backgroundColorButtonHover: '',
    //authorizationKey: 494,
}];
export const rightButtons = [{
    key: 'BtnNew',
    text: 'Nuevo',
    iconName: 'addcuvitek',
    fontColor: '#FFFFFF',
    iconColor: '#fff',
    iconHover: '#fff',
    backgroundColorButton: 'var(--primary)',
    backgroundColorButtonHover: 'var(--primary)',
    isButton: true,
    component: <Add />,
    //authorizationKey: 492,
}]