import {
    TableBody, TableCell, TableRow, Table, TableHeader,
    TableHeaderCell, TableCellLayout, Button, Image, Tooltip, makeStyles, Text
} from "@fluentui/react-components";
import "../../../Assets/Styles/Shared/Table/DetailTable.css"
import '../../../Assets/Styles/Shared/Controls/OmniLabel.css'
import { useRef, useEffect, useState } from 'react';
import { useFieldChangeWatcherContext } from "../../../Context/Controls/FieldChangeWatcherProvider"
import { OmniCombobox } from "../Controls/ComboBox/OmniCombobox";
import { DangerMessage } from "../../../Models/Shared/BarMessages/Messages";
import { useGeneralStatesContext } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
const trashIcon = require('../../../Assets/Images/Icons/Trash.svg').default;
const stylesTooltipAll = makeStyles({
    tooltip: {
        backgroundColor: 'var(--primary)',
        color: 'white',
    }
});
export const Add = (props) => {
    const columnsHeaderEmails = [
        { columnKey: "1", label: props.titleColum, width: '70%', classAdd: 'first-item-delete' },
        { columnKey: "2", label: "", width: '15%' },
    ];
    const stylesTooltip = stylesTooltipAll();
    const fieldEdited = useRef(false);
    const initialFieldValue = useRef(GetInitialSelectedOption());
    const fieldWatcher = useFieldChangeWatcherContext();
    const [showSpinner, setShowSpinner] = useState(false);
    const [itemListConverted, setItemListConverted] = useState();
    const [serch, setSerch] = useState();
    const [cleanText, setCleanText] = useState(false);
    function GetInitialSelectedOption() {
        let initialSelectedOpction;
        if (props?.description[props.property]) {
            initialSelectedOpction = props?.description[props.property].length
        }
        else {
            initialSelectedOpction = 0;
        }
        return initialSelectedOpction;
    };
    function DeleteItem(id,text) {
        const filteredList = props.description[props.property].filter((itemfilter) => itemfilter.id !== id);
        props.setDescription(prevEmail => ({ ...prevEmail, [props.property]: filteredList }));
        ChangeFieldEdit(filteredList.length);
        const newItem = {
            id: id,
            description: text
        };
        props.setItemList([...props.itemList, newItem]);
    }
    //init control on Context
    useEffect(() => {
        if (!fieldWatcher.contextControlsRef.current.some(item => item.id === props.property)) {
            fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: props.property, edited: false }];
        }
        fieldWatcher.updateHasPendingChanges();
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
            fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                if (c.id === props.property) {
                    return { ...c, edited: fieldEdited.current }
                }
                return c;
            }));
            fieldWatcher.updateHasPendingChanges();
        }

    }, [fieldEdited.current]);
    function ChangeFieldEdit(length) {
        if (!props.inactivefieldEdited) {
            if (initialFieldValue.current === length) {
                fieldEdited.current = false;
            }
            else {
                fieldEdited.current = true;
            }
        }
    }
    function LoadList(text) {
        if (text) {
            setShowSpinner(false);
            const words = text.toLowerCase().split(' ');
            let filteredData = props.itemList.slice();
            for (const word of words) {
                if (word) {
                    filteredData = filteredData.filter(item =>
                        item.description.toLowerCase().includes(word)
                    );
                }
            }
            if (filteredData.length > 0) {
                const resultList = filteredData.map((item) => ({
                    key: item.id,
                    value: item.id,
                    text:item.description
                })).slice(0, 100);;
                setItemListConverted(resultList);
            }
        }
    }
    useEffect(() => {
        if (props.itemList) {
            LoadList(serch)
        }
    }, [props.itemList]);
    const OnChangeText = async (text) => {
        setCleanText(false);
        setShowSpinner(true);
        if (props.itemList) {
            LoadList(text)
        }
        else {
            setSerch(text);
            setShowSpinner(true);
        }
    }
    const onChangeItem = async (id, convertedItem) => {
        setCleanText(true);
        const updatedList = [...props.description[props.property], { description: convertedItem, id: id }];
        props.setDescription(prevEmail => ({ ...prevEmail, [props.property]: updatedList }));
        ChangeFieldEdit(updatedList.length);
        const filteredList = props.itemList.filter((itemfilter) => itemfilter.id != id);
        props.setItemList(filteredList);
        const filteredListItem = itemListConverted.filter((itemfilter) => itemfilter.value != id);
        setItemListConverted(filteredListItem);
    }
    return (
        <>
            <OmniCombobox
                showSpinner={showSpinner}
                title={props.titleComboBox}
                items={itemListConverted}
                onChangeItemText={OnChangeText}
                onChangeItem={onChangeItem} inactivefieldEdited
                cleanOnChangeItem
            />

            <div className="detail-table-scroll header-iconEdit">
                {
                    fieldEdited.current === true &&
                    <div className="omniinfolabel-icon">
                        <Image alt="" src={require("../../../Assets/Images/Icons/Edit.svg").default} />
                    </div>
                }
                <Table className="detail-table">
                    <TableHeader>
                        <TableRow>
                            {columnsHeaderEmails.map((column) => (
                                <TableHeaderCell key={column.columnKey}
                                    style={{ width: column.width }}
                                    className={'detail-table-header-title ' + column?.classAdd}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {props.description[props.property] &&
                            props.description[props.property].map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell >
                                        <TableCellLayout className='delete-table-body-description'>
                                            {
                                                props.isItemDescriptionList!=undefined && props.isItemDescriptionList
                                                ?(
                                                    props.useTooltip
                                                    ?(
                                                        <Tooltip
                                                            withArrow
                                                            positioning="after"
                                                            content={{ 
                                                                children: item.description.split('-')[0]
                                                                , className: stylesTooltip.tooltip 
                                                            }}>
                                                            <span>
                                                                {
                                                                    item.description.split('-').length>1
                                                                    ? item.description.split('-')[1]
                                                                    :item.description
                                                                }
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                    :<></>
                                                )
                                                :item.description
                                            }
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout >
                                            <Tooltip
                                                withArrow
                                                positioning="after"
                                                content={{ children: "Eliminar", className: stylesTooltip.tooltip }}>
                                                <Button icon={<Image src={trashIcon} alt="delete" />} onClick={() => DeleteItem(item.id,item.description)} />
                                            </Tooltip>
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}