import ItemsSelector from '../../Shared/ItemsSelector/ItemsSelector';
import Detail from './Detail';
import { rightButtons } from '../../../Assets/Constants/BarButtons/Catalog/Warehouse/Warehouse';
import { ItemSelectorProvider } from '../../../Functions/Shared/ItemsSelector/ItemSelectorProvider';
import { AccountConfigurationProvider } from '../../../Context/Account/AccountConfigurationProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const GetByStatus = '/warehouse/getbystatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/Warehouse.svg').default
}
export default function Warehouse()
{
    return (
        <ItemSelectorProvider>
            <AccountConfigurationProvider>
            <FieldChangeWatcherProvider>
                <ItemsSelector
                    title={"Catálogo de almacenes"}
                    image={iconCatalog}
                    urlGetItems={GetByStatus}
                    defaultButtonsRightBar={rightButtons}
                    detailComponent={<Detail />}
                    searchActiveInRender={false}
                    omniKey={"id"}
                    omniValue={"name"}
                    omniAcronym={"acronym"}
                    getInLoad={false}
                    indexComponent={<Index />}
                />
                </FieldChangeWatcherProvider>
            </AccountConfigurationProvider>
        </ItemSelectorProvider>
    )
}