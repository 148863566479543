import axios from 'axios';
const url = {
    base: '/branch',
    getbranchs: '/getbranchs',
}

export const GetBranchsAsync = async () => {
    var result = {
        isSuccessful: false,
        data: null,
        errorMessage: ""
    }
    try {
        //if (localStorage.getItem('listBranch') !== null);
        if (localStorage.getItem('listBranch') !== undefined && localStorage.getItem('listBranch')) 
        {
            let data = JSON.parse(localStorage.getItem('listBranch'));
            return { isSuccessful: true, data, errorMessage: "" };
        }
        const response = await axios.get(url.base + url.getbranchs);
        if (response.data.isSuccessful) {
            const data = response.data.data.map(item => ({
                key: item.idSucursal,
                value: item.idSucursal, // + '#' + item.claveSucursal,
                text: item.nombreSucursal,
                brachKey: item.claveSucursal,
            }));
            localStorage.setItem('listBranch', JSON.stringify(data));
            return { isSuccessful: true, data, errorMessage: "" };
        } else {
            return { isSuccessful: false, data: null, errorMessage: response.data.errorMessage };
        }
    }
    catch (err) {
        let errorMessage = getErrorText(err);
        result.errorMessage = errorMessage;
    }
    return result;
};

const getErrorText = (err) => {
    if (!err?.response) {
        return "No server response";
    } else if (err.response?.status === 400) {
        return err.response?.data?.errorMessage
    }
}