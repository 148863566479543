import React, { useState, useEffect, useContext, createContext } from "react";

const accountConfigurationContext = createContext();

export function useAccountingConfigurationContext() {
    return useContext(accountConfigurationContext);
}

export const AccountConfigurationProvider = ({ children }) => {
    const [useAccounting, setUseAccounting] = useState(false);
    const [extendedAccounting, setExtendedAccounting] = useState(false);
    const [baseCustomers, setBaseCustomers] = useState('');
    const [baseSuppliers, setBaseSuppliers] = useState('');

    useEffect(() => {
        const accountConfigurations = localStorage.getItem("AccountConfigurations");
        if (accountConfigurations !== null && accountConfigurations !== 'null') {
            const configurations = JSON.parse(accountConfigurations);
            setUseAccounting(configurations.useAccounting);

            if (configurations.useAccounting) {
                const isExtendedAccounting = configurations.accounting.accountingType === 2;
                setExtendedAccounting(isExtendedAccounting);

                if (isExtendedAccounting) {
                    setBaseCustomers(configurations.accounting.customers + '.');
                    setBaseSuppliers(configurations.accounting.suppliers + '.');
                }
            }
        }
    }, []);

    return (
        <accountConfigurationContext.Provider
            value={{
                useAccounting,
                extendedAccounting,
                baseCustomers,
                baseSuppliers
            }}
        >
            {children}
        </accountConfigurationContext.Provider>
    )
}