import { Field } from "@fluentui/react-components";
import { OmniFieldLabel } from '../FieldLabel/OmniFieldLabel'
import '../../../../Assets/Styles/Shared/Controls/OmniDatePicker.css'
import { useRef, useState, useEffect } from 'react';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { DayPickerStrings } from '../../../../Assets/Constants/Enums/TraslateDatePicker.js';
import { ReactComponent as Calendar } from '../../../../Assets/Images/Icons/CalendarBlue.svg';
import { useFieldChangeWatcherContext } from "../../../../Context/Controls/FieldChangeWatcherProvider"
export const OmniDatePicker = (props) => {
    const formControlId = props?.register?.name ?? props?.property;
    const [selectedValue, setSelectedValue] = useState(formatDateValue(props.value));
    const initialFieldValue = props?.value ?? '';
    const fieldEdited = useRef(false);
    const fieldWatcher = useFieldChangeWatcherContext();
    function formatDateValue(date) {
        if (date) {
            return new Date(date);
        }
        return '';
    }
    const handleDateChange = (date) => {
        if (initialFieldValue == date) {
            fieldEdited.current = false;
        }
        else {
            if (!props.inactivefieldEdited) {
                fieldEdited.current = true;
            }
        }
        setSelectedValue(date);
        if (props.property && props.edit) {
            props.edit(prevEedit => ({ ...prevEedit, [props.property]: date }));
        }
    };

    //init control on Context
    useEffect(() => {
        if (fieldWatcher) {
            if (!fieldWatcher.contextControlsRef.current.some(item => item.id === formControlId)) {
                fieldWatcher.contextControlsRef.current = [...fieldWatcher.contextControlsRef.current, { id: formControlId, edited: false }];
            }
            fieldWatcher.updateHasPendingChanges();
        }
    }, []);
    //update context
    useEffect(() => {
        if (fieldWatcher) {
            if (fieldWatcher.contextControlsRef.current && fieldWatcher.contextControlsRef.current.length > 0) {
                fieldWatcher.contextControlsRef.current = (fieldWatcher.contextControlsRef.current.map(c => {
                    if (c.id === formControlId) {
                        return { ...c, edited: fieldEdited.current }
                    }
                    return c;
                }));
                fieldWatcher.updateHasPendingChanges();
            }
        }
    }, [fieldEdited.current]);
    const handleBlur = (event) => {
        props.onBlur && props.onBlur(event);
        //setSelectedValue(new Date()); allowTextInput
    };
    useEffect(() => {
        setSelectedValue(formatDateValue(props.value));
    }, [props?.value]);
    return (
         <>
            <div className={'container-controls'}>
                <Field className={"label-omni"}
                    validationState={props.validationMessage === undefined ? "none" : "error"}
                    validationMessage={props.validationMessage ?? null}>
                    <OmniFieldLabel {...props} formControlId={formControlId} fieldEdited={fieldEdited.current}></OmniFieldLabel>
                    <DatePicker  
                        placeholder="Seleccione"
                        className={"datepicker-omni"}
                        underlined={true}
                        onSelectDate={handleDateChange}
                        onBlur={handleBlur}
                        showMonthPickerAsOverlay={true}
                        showGoToToday={false}
                        strings={DayPickerStrings}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        contentAfter={<Calendar />}
                        calendar={{
                            restrictedDates: props.restrictedDates === undefined ? undefined : props.restrictedDates(),
                        }}

                        formatDate={(date) => {
                            const day = `0${date.getDate()}`.slice(-2);
                            const month = `0${date.getMonth() + 1}`.slice(-2);
                            const year = date.getFullYear().toString().slice(-2);
                            return `${day}/${month}/${year}`;
                        }}
                        value={selectedValue === '' ? props?.initialPickerDate : selectedValue}
                        initialPickerDate={props.initialPickerDate}
                    />
                </Field>
            </div>
        </>
    );
}