import ItemsSelector from "../../Shared/ItemsSelector/ItemsSelector";
import Detail from "./Detail";
import { rightButtons } from "../../../Assets/Constants/BarButtons/Catalog/Customers/Customer"
import { ItemSelectorProvider } from "../../../Functions/Shared/ItemsSelector/ItemSelectorProvider";
import { AccountConfigurationProvider } from '../../../Context/Account/AccountConfigurationProvider';
import { FieldChangeWatcherProvider } from '../../../Context/Controls/FieldChangeWatcherProvider';
import { Index } from './Index';
const getByStatusUrl = '/customer/GetByStatus';
const iconCatalog = {
    src: require('../../../Assets/Images/Icons/CustomerBlue.svg').default
}
export default function Customer() {
    return (
        <ItemSelectorProvider>
            <AccountConfigurationProvider>
                <FieldChangeWatcherProvider>
                    <ItemsSelector
                        title={"Catálogo de clientes"}
                        image={iconCatalog}
                        urlGetItems={getByStatusUrl}
                        defaultButtonsRightBar={rightButtons}
                        detailComponent={<Detail />}
                        searchActiveInRender={false}
                        omniKey={"id"}
                        omniValue={"businessName"}
                        omniAcronym={"updateUserName"}
                        getInLoad={false}
                        indexComponent={<Index />}
                    />
                </FieldChangeWatcherProvider>
            </AccountConfigurationProvider>
        </ItemSelectorProvider>
    )
}